@import '_variables';

.border-bottom {
  border-width: 0 0 1px 0;
}

.border-left {
  border-width: 0 0 0 1px;
}

.border-radius {
  border-radius: $border-radius-size;
}

.border-right {
  border-width: 0 1px 0 0;
}

.border-solid {
  border-style: solid;
}

.border-top {
  border-width: 1px 0 0 0;
}

.border-x {
  border-width: 0 1px 0 1px;
}

.border-y {
  border-width: 1px 0 1px 0;
}

.borders {
  border-width: 1px;
}

.box-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.no-border {
  border: none !important;
}

.white-border {
  border-color: color(white);
}
