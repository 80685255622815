.flex {
  display: flex;
  justify-content: center;
}
.flex-inline {
  display: inline-flex;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-align-items-center {
	align-items: center;
}
.flex-1 {
  flex: 1;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-shrink {
  flex-shrink: 1;
}

.flex-grow {
  flex-grow: 1;
}
.flex-column-grow {
  flex: 1 0 auto;
}

.justify-between {
  align-items: center;
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-normal {
  align-items: center;
  justify-content: normal;
}
.justify-end {
  align-items: center;
  justify-content: flex-end !important;
}
.justify-start {
  align-items: center;
  justify-content: flex-start !important;
}
.align-normal {
  align-items: normal;
}
.align-flex-end {
  align-items: flex-end;
}
.align-flex-start {
  align-items: flex-start;
}
.align-self-center {
  align-self: center;
}

.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

.align-bottom {
  align-items: flex-end;
}
