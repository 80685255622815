.grid {
  display: grid;
  grid-row-gap: $grid-gap;
  grid-column-gap: $grid-gap;
  padding: 0 8px;
}

@for $i from 2 through $cols {
  .grid-#{$i} {
    @extend .grid;
    grid-template-columns: repeat($i, 1fr);
  }
}

@for $i from 1 to $cols + 1 {
  .col-#{$i} {
    grid-column-end: span $i;
    padding: 0;
  }

  @for $y from 1 through $cols {
    .col-start-#{$y}.col-#{$i} {
      grid-column: #{$y} / span #{$i};
    }
  }
}

.grid-column-full-width {
  grid-column: 1 / -1;
}
.grid-row-full-height {
  grid-row: 1 / -1;
}
.grid-gap-16 {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.no-horizontal-gap {
  grid-column-gap: 0;
}