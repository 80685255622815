@import '_variables';

.font-normal {
	font-weight: 400;
}

.font-medium {
	font-weight: 500;
}

.font-bold {
	font-weight: 700;
}

h1 {
	@extend .font-bold;
	font-size: 24px;
	line-height: 14px;
	letter-spacing: 0;
}

h2 {
	@extend .font-bold;
	font-size: 18px;
	line-height: 14px;
	letter-spacing: 0;
}

h3 {
	@extend .font-bold;
	font-size: 16px;
	line-height: 14px;
	letter-spacing: 0;
}

.fz8 {
	font-size: 8px;
}

.fz10 {
	font-size: 10px;
}

.fz11 {
	font-size: 11px;
}

.fz12 {
	font-size: 12px;
}

.fz13 {
	font-size: 13px;
}

.fz14 {
	font-size: 14px;
}

.fz16 {
	font-size: 16px;
}

.fz18 {
	font-size: 18px;
}

.fc-white {
	color: white;
}

.fc-black {
	color: black;
}

.underline {
	text-decoration: underline;
}

.italic {
	font-style: italic;
}

.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.text-center {
	text-align: center;
}
.text-justify {
	text-align: justify;
}
.error {
	color: $red;
}
