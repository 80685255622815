@import '_variables';

.modal-content {
  display: contents;
}

.overlay {
  .overlay-wrap {
    width: 500px;
    background-color: $white;

    textarea.simple {
      width: 100%;
      border: $borderLightGrey;
      overflow-x: hidden;
      overflow-y: auto;
      padding: $defaultPadding ($defaultPadding * 5) $defaultPadding $defaultPadding;
      box-sizing: border-box;
      box-shadow: none;
      -moz-box-shadow: none;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -webkit-box-shadow: none;
      outline: 0;
      resize: none;
      min-height: 61px;
    }

    &.xml {
      overflow: hidden;
    }

    &.overlay-wrap-wide {
      width: 800px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin: 50px 0;
    }

    &.overlay-wrap-extra-wide {
      width: 900px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin: 50px 0;
    }

    &.overlay-full {
      width: 90%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin: 50px 0;
      flex: 1;
    }

    .title-wrap {
      height: 40px;
      background: $blueGrey;
      color: $black;
      font-size: $defaultFontSize;
      font-weight: $bold;
      line-height: 40px;
      vertical-align: middle;
      padding: 0 $doubleDefaultPadding;
      flex: 0 0 40px;
    }

    .overlay-content {
      padding: $doubleDefaultPadding;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      flex: 1 1 auto;

      .flex-full-height {
        overflow-y: auto;
      }

      .mail-body {
        white-space: pre;
        overflow: auto;
        max-height: 40vh;
        width: 100%;
        margin: 0;
      }

      .payment-wrap {
        margin-bottom: $doubleDefaultMargin;
        display: flex;
        align-items: center;
        border: $borderPrimaryColor;
        padding: $doubleDefaultPadding;

        .icon {
          flex: 0 0 30px;
          width: 30px;

          i {
            font-size: 30px;
            color: $primaryColorLighter;
          }
        }

        .lines-wrap {
          flex: 1 1 auto;
        }
      }

      &.sap-creditor-search {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1 1 auto;

        .summary-line {
          margin: $smallMargin $defaultMargin;

          .title {
            font-weight: $bold;
            color: $primaryColor;
          }
        }

        .filters {
          padding: 0 $defaultPadding;

          .filter {
            display: inline-block;
            margin-right: $slightlySmallerMargin;
          }
        }
      }

      &.submit-user-task-wrap {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1 1 auto;

        .required-header {
          font-weight: $bold;
        }

        .search-bar {
          height: 28px;
          flex: 0 0 28px;
          position: relative;
          margin-bottom: $doubleDefaultMargin;

          .fa {
            position: absolute;
            top: 7px;
            font-size: $fontAwesomeSmallFontSize;
            color: $grey;
            left: $defaultMargin;
          }

          input {
            width: 100%;
            background-color: $white;
            padding: $defaultPadding 0 $defaultPadding 20px;
            border: $borderLightGrey;

            &:hover,
            &:focus,
            &:active {
              border: $borderLighterGrey;
            }

            font-size: $defaultFontSize;
          }
        }

        .process-step {
          margin-bottom: $doubleDefaultMargin;

          .process-step-name {
            color: $white;
            background-color: $complementaryColor;
            text-align: center;
            font-weight: $bold;
            padding: $doubleDefaultPadding 0;

            i {
              margin-right: $defaultMargin;
            }
          }
        }

        .selection {
          display: flex;
          flex-direction: row;
          overflow: hidden;

          h2 {
            font-size: 14px;
            color: $primaryColor;
            font-weight: 600;
            display: block;
            margin: 0 0 $defaultMargin 0;
            border-bottom: $borderPrimaryColor;
            padding-bottom: $defaultPadding;
          }

          .group-selection {
            min-width: 250px;
            margin-right: $doubleDefaultMargin;
            border-right: $borderPrimaryColor;
            padding-right: $defaultPadding;
            overflow: hidden;
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;

            ul {
              overflow-x: hidden;
              overflow-y: auto;
            }

            li {
              padding: $smallerPadding 0;
              cursor: pointer;
              color: $black;
              display: flex;
              align-items: center;
              justify-content: center;

              .icon {
                visibility: hidden;
              }

              .lines {
                flex: 1 1 auto;
                padding: 0 $slightlyBiggerPadding;
              }

              .counter {
                color: $white;
                background-color: $primaryColor;
                text-align: center;
                padding: 0 $defaultPadding;
                min-width: 25px;
                margin-right: $defaultMargin;
              }

              &.active,
              &:hover {
                background-color: $complementaryColor;
                color: $white;

                .icon {
                  visibility: visible;
                }
              }
            }
          }

          .user-selection {
            flex: 1 1 auto;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            > div {
              overflow-y: auto;
            }

            ul {
              overflow-x: hidden;
              overflow-y: auto;
            }

            li {
              cursor: pointer;
              color: $black;
              padding: $smallPadding 0;
              display: flex;
              align-items: center;
              justify-content: center;

              .icon {
                visibility: hidden;
              }

              .lines {
                flex: 1 1 auto;
                padding: 0 $slightlySmallerPadding;

                .user-suffix {
                  margin-left: $slightlySmallerMargin;
                  font-style: italic;
                }

                .user-group {
                  margin-left: $slightlySmallerMargin;
                  color: $primaryColor;
                  font-weight: $bold;
                }
              }

              &.active,
              &:hover {
                background-color: $complementaryColor;
                color: $white;

                .icon {
                  visibility: visible;
                }
              }
            }
          }

          textarea {
            width: 100%;
            border: 1px solid #ccc;
            overflow-x: hidden;
            overflow-y: auto;
            padding: $defaultPadding 25px $defaultPadding $defaultPadding;
            box-sizing: border-box;
            box-shadow: none;
            -moz-box-shadow: none;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -webkit-box-shadow: none;
            outline: 0;
            resize: none;
            min-height: 61px;
          }
        }
      }

      .btns-wrap {
        margin: 0;
      }

      .input-wrap {
        margin-bottom: $defaultMargin;

        label {
          display: inline-block;
          margin: 0;
        }

        input.input-text-field {
          width: 100%;
          background-color: $white;
          padding: $smallPadding $defaultPadding;
          border: $borderLightGrey;
          color: $black;
          font-size: $defaultFontSize;

          &:hover,
          &:focus,
          &:active {
            border: $borderLighterGrey;
          }

          &.ng-invalid {
            border: $borderRed;
          }
        }

        .textarea {
          width: 100%;
          min-height: 150px;
          padding: $defaultPadding;
          font-size: $defaultFontSize;
          color: $black;

          &.ng-invalid {
            border: $borderRed;
          }
        }
      }

      .input-wrap-checkbox {
        vertical-align: middle;

        input[type='checkbox'],
        input[type='radio'] {
          margin: 0 $defaultMargin 0 0;
          vertical-align: middle;
        }
      }

      .attachments-list {
        margin: 0;
      }

      .hint-wrap {
        text-align: right;
        margin-top: $defaultMargin;

        .icon {
          font-weight: $bold;
          color: $complementaryColor;
          font-size: $fontAwesomeSmallFontSize;
        }

        .hint {
          color: $primaryColor;
          font-weight: $bold;
        }
      }

      .textbox {
        margin: $doubleDefaultMargin 0;
        max-height: 200px;
        overflow-y: auto;
        white-space: pre;

        &.large {
          max-height: 400px;
        }
      }

      .textbox-textarea {
        width: 100%;
        height: 300px;
        padding: $defaultPadding;
      }

      .explain {
        margin: $doubleDefaultMargin 0;
      }

      &.sla-quality-table-notes-wrap {
        .notes {
          overflow-y: auto;
          overflow-x: auto;
          max-height: 200px;

          ul {
            min-height: 0; // thanks, Firefox. Not even IE11 needs this hack

            .message {
              display: block;
              position: relative;
              margin-bottom: $defaultMargin;
              border-bottom: $borderLightGrey;

              &:nth-last-of-type(1) {
                margin: 0;
                border: 0;
              }

              .username {
                font-weight: $bold;
              }

              .message-text {
                display: inline-block;
                vertical-align: top;
                box-sizing: border-box;
                line-height: $normalLineHeight;

                time {
                  position: absolute;
                  top: 0;
                  right: 0;
                  color: $grey;
                }
              }
            }
          }
        }
      }
    }

    .btns-wrap {
      display: flex;
      margin: 0 $doubleDefaultMargin $doubleDefaultMargin $doubleDefaultMargin;
      flex-direction: row;
      justify-content: flex-end;

      .btn {
        margin-left: ($doubleDefaultMargin * 2);
      }
    }

    .error {
      position: fixed;
      z-index: 100001;
      top: 0;
      left: 0;
      margin: auto;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: $bold;

      > div {
        width: 400px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        //.box();

        div {
          margin: $doubleDefaultMargin;
          text-align: center;
        }
      }
    }
  }
}
