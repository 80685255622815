/* purgecss start ignore */
@charset "utf-8";

@media print {
	/* All your print styles go here */

	app-header,
	app-footer,
	nav,
	.btn,
	.case-details-bottom-navigation {
		display: none !important;
	}

	#case-viewer-container {
		display: none !important;
	}

	#case-details-container {
		flex: 0 0 100% !important;
	}

	.case-top-line {
		.btn-wrap {
			display: none !important;
		}
	}

	body {
		overflow-y: visible !important;
		overflow-x: visible !important;
		height: auto;
	}

	.tab-content {
		overflow: visible !important;
		display: block;
	}
}

/* purgecss end ignore */
