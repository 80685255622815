$margins: (
  0,
  1,
  2,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  12,
  13,
  14,
  16,
  18,
  19,
  20,
  22,
  24,
  26,
  28,
  30,
  32,
  34,
  36,
  38,
  40,
  44,
  48,
  52,
  56,
  60,
  64,
  68,
  72,
  76,
  80
);
$sides: (top, bottom, left, right);

@each $space in $margins {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }

  // Axes
  .mx-#{$space} {
    margin-left: #{$space}px !important;
    margin-right: #{$space}px !important;
  }
  .my-#{$space} {
    margin-top: #{$space}px !important;
    margin-bottom: #{$space}px !important;
  }
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .px-#{$space} {
    padding-left: #{$space}px !important;
    padding-right: #{$space}px !important;
  }
  .py-#{$space} {
    padding-top: #{$space}px !important;
    padding-bottom: #{$space}px !important;
  }
  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
