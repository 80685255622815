@import '_variables';
@import '_tenant';

.simple-table {
  width: 100%;

  th {
    background: $primaryColor;
    padding: $defaultPadding;
    color: $white;

    a {
      color: $white;

      i {
        font-size: $slightlySmallerFontSize;
        margin-left: $smallMargin;
      }
    }
  }

  td {
    padding: $defaultPadding;
    border-bottom: $borderVeryLightGrey;
    overflow-x: hidden;
    text-overflow: ellipsis;

    &.case-img, .case-img {
      text-decoration: underline;
    }

    .case-note {
      text-decoration: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 50px;
    }

    &.text-middle {
      text-align: center;
    }

    &.disabled {
      text-decoration: line-through;
      color: lighten($black, 50);
    }
  }

  tr {
    border-left: $borderVeryLightGrey;
    border-right: $borderVeryLightGrey;

    &:hover {

      td {
        background: $lightGrey;
      }
    }

    &.selected {

      td {
        font-weight: $bold;
        color: $primaryColor;
      }
    }
  }

  .badge {
    border-radius: 0;
    background-color: $white;
    color: $primaryColor;
  }

  .badge.badge-error {
    background-color: $red;
    color: $white;
  }

  .badge.badge-warning {
    background-color: $complementaryColor;
    color: $white;
  }

  .badge.badge-success {
    background-color: $green;
    color: $white;
  }

  .table-head {

    .no-sort {
      font-weight: normal;
    }

    a {
      display: block;
      font-weight: normal;
      &.inline-block{
        display:inline-block;
      }

      &.active {
        color: $yellow;
        font-weight: $bold;
        text-decoration: underline;
      }
    }

    input, select {
      display: block;
      font-size: $defaultFontSize;
      line-height: $defaultLineHeight;
      font-weight: normal;
      color: $black;
      padding: $smallPadding;
      min-width: 30px;
      max-width: 150px;
      width: 100%;

      &.filter-input-date {
        display: block;
        width: 100%;
      }
    }
  }

  &.admin-table {

    em {
      background-color: $yellow;
      font-style: normal;
      margin-right: 2px;
    }

    .bot-margin {
      margin-bottom: $smallMargin;
    }

    &.assignment-summary-table {
      table-layout: fixed;

      .similar-text{
        color: $green;
      }

      tr{
        &.selected-assignment-summary{
          background-color: $lightGrey;
        }
      }
      td, th {
        padding: $smallPadding;
      }

      td{
        border-bottom: 1px solid $grey;
        .selected-assignment-summary{
          color: $green;
          font-weight: bold;
        }
      }

      .linkable {
        cursor: pointer;

        :hover {
          color: $primaryColor;
        }
      }

      .assignment-pos-line {

        .price{
          display: inline-block;
          &.price-total, &.price-net{
            width: 100px;
          }
        }

        .total-price {
          width: 100px;
          display: inline-block;
          >span{
            font-weight: $bold;
            background-color: $primaryColor;
            color: $white;
          }
        }

        .proportion {
          background-color: #000071;
          color: #fff;
          font-weight: $bold;
        }

        .posting-key {
        }

        .gl-account {
          font-weight: $bold;
          color: $primaryColor;
        }

        .object-ident-key, .bu {
          font-weight: $bold;
          background-color: $complementaryColor;
          color: $white;
        }

        .target-obj-title{
          margin-left: 2px;
        }

        .target-object{
          font-weight: $bold;
          width: 21px;
          display: inline-block;
          margin-right: 4px;
        }

        .entity-texts {
          color: $black;
        }

        .pos-text {
          background-color: $green;
          color: $white;
          .literal{
            background-color: darken($green, 20);
          }

          .matched-value{
            font-style: italic;
          }
        }
      }
    }

    &.sla-quality-table {

      td > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .sla-failed {
        word-break: break-word;
        -ms-word-break: break-all;
      }
    }

    th {
      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .captured-value {
        background-color: $complementaryColor;
        color: $white;
        display: inline-block;
      }

      .input-filter {
        input {
          padding: 0;
          margin: 0;
          display: block;
          min-width: auto;
          max-width: none;
        }
      }
    }

    tr.ellipsize {
      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    td {
      &.openable {
        &:hover {
          cursor: pointer;
        }
      }

      .prev-value {
        color: $grey;
        font-style: italic;
      }

      .new-value {
        color: $primaryColor;
        font-weight: bold;
      }
    }
  }

  &.narrow-table {
    td, th {
      padding: 1px 3px;

      .badge {
        padding: 1px 5px;
        font-size: $defaultFontSize;
        font-weight: normal;

        &.badge-color-1 {
          background-color: $green;
          color: $white;
        }

        &.badge-color-2 {
          background-color: $black;
          color: $white;
        }

        &.badge-color-3 {
          background-color: $primaryColor;
          color: $white;
        }
      }

      .btn, .btn-primary.btn-sm {
        padding: 1px 3px;
      }

      .btn {
        &.btn-np {
          padding: 0 2px;
        }
      }
    }

    th {
      padding: 3px;
    }
  }
}
