.wrap {
	display: flex;
	height: 100%;
	max-height: 100%;
	overflow: hidden;
	flex-direction: column;

	.page-wrap {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		overflow: hidden;
	}
}

body.wrap-overflow{
	app-root>.wrap{
		max-height: 100%;
		overflow: auto;
	}
}
