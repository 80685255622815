@import 'variables';

.common-wrap {
	.input-block {
		&.has-error {
			.ui-select-bootstrap > .ui-select-match > .btn {
				border-color: $red;
			}
		}
	}

	.gs-creditor-banks-error {
		color: $red;
		font-weight: $bold;
	}

	.likely-creditors {
		.likely-creditor {
			margin-bottom: $defaultMargin;
		}

		.editor {
			display: flex;
			margin-bottom: $tinyMargin;

			.name {
				flex: 1;
				font-weight: $bold;
			}

			.percent {
				color: $primaryColor;
				font-weight: $bold;
				margin-left: $doubleDefaultMargin;
			}
		}

		.percent-bar {
			background-color: $complementaryColor;
			height: $percentHeight;
		}
	}

	.creditor-search-wrap {
		display: flex;

		app-lookup {
			flex: 1;
		}

		.search-btn {
			height: $inputHeight;
		}
	}

	.gs-creditor-banks {
		.bank-list {
			li {
				padding: $smallPadding 0;

				.positive {
					color: $green;
				}

				.negative {
					color: $red;
				}

				.autocomplete-id {
					font-style: italic;
				}

				.autocomplete-name {
					font-weight: $bold;
					color: $primaryColor;
				}

				.autocomplete-comment {
					font-weight: $bold;
				}

				.counter {
					font-weight: $bold;
				}

				&:hover {
					cursor: pointer;
					color: $white;
					background-color: $complementaryColor;
				}
			}
		}
	}

	.gs-creditor-btns {
		margin-bottom: $doubleDefaultMargin;
	}

	.creditor-btns {
		margin-top: $defaultMargin;
	}

	.creditor-error-msg {
		.text {
			background-color: $red;
			color: $white;
			padding: $smallPadding;
			margin-bottom: $defaultMargin;

			.icon {
				margin-right: $defaultMargin;
			}
			font-weight: $bold;
		}
	}
}
