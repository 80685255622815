@import '_variables';
@import '_tenant';

html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	-ms-overflow-style: scrollbar;
	height: 100%;
}
.logo-img {
	content: $logoImg;
	display: block;
	max-width: 250px;
	max-height: 60px;
	width: auto;
	height: auto;
	margin: 0 auto;
}
header .logo-img {
	max-height: 30px;
}
.logo-immo-img {
	content: $logoImmo;
	display: block;
	max-width: 150px;
	max-height: 30px;
	width: auto;
	height: auto;
	margin: 0 auto;
}
body {
	margin: 0;
	padding: 0;
	font-family: $defaultFont;
	font-size: $defaultFontSize !important;
	background-color: $veryLightGrey;
	height: 100%;
	line-height: 1.42857143;
	-ms-overflow-style: scrollbar;
	overflow-y: hidden;
}
a,
a:not([href]),
a:not([href]):not([class]) {
	color: $complementaryColor;
	text-decoration: none !important;
	&:hover {
		color: $complementaryColor;
		cursor: pointer;
	}
}
.flex-full-height {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
}
.btns-wrap {
	display: flex;
	margin: 0 $doubleDefaultMargin $doubleDefaultMargin $doubleDefaultMargin;
	flex-direction: row;
	justify-content: flex-end;

	.btn {
		margin-left: ($doubleDefaultMargin * 2);
	}
}

.tooltip {
	font-size: $defaultFontSize;

	.tooltip-inner {
		padding: 3px 8px;
		border-radius: 4px;
	}

	&.bs-tooltip-bottom {
		margin-top: 3px;
	}

	&.warning {
		&.bs-tooltip-bottom {
			.arrow:before {
				border-bottom-color: $complementaryColor;
			}
		}

		&.bs-tooltip-right {
			.arrow:before {
				border-right-color: $complementaryColor;
			}
		}

		.tooltip-inner {
			background-color: $complementaryColor;
		}
	}

	&.invalid {
		&.bs-tooltip-bottom {
			.arrow:before {
				border-bottom-color: $red;
			}
		}

		&.bs-tooltip-right {
			.arrow:before {
				border-right-color: $red;
			}
		}

		.tooltip-inner {
			background-color: $red;
		}
	}

	&.readonly {
		&.bs-tooltip-bottom {
			.arrow:before {
				border-bottom-color: $darkerGrey;
			}
		}

		&.bs-tooltip-right {
			.arrow:before {
				border-right-color: $darkerGrey;
			}
		}

		.tooltip-inner {
			background-color: $darkerGrey;
		}
	}
}

.tooltip-error {
	.tooltip-inner {
		background-color: $red;
	}

	&.bs-tooltip-top .arrow:before {
		border-top-color: $red !important;
	}

	&.bs-tooltip-right .arrow:before {
		border-right-color: $red !important;
	}

	&.bs-tooltip-bottom .arrow:before {
		border-bottom-color: $red !important;
	}

	&.bs-tooltip-left .arrow:before {
		border-left-color: $red !important;
	}
}

.tooltip-warning {
	.tooltip-inner {
		background-color: $complementaryColor;
	}

	&.bs-tooltip-top .arrow:before {
		border-top-color: $complementaryColor !important;
	}

	&.bs-tooltip-right .arrow:before {
		border-right-color: $complementaryColor !important;
	}

	&.bs-tooltip-bottom .arrow:before {
		border-bottom-color: $complementaryColor !important;
	}

	&.bs-tooltip-left .arrow:before {
		border-left-color: $complementaryColor !important;
	}
}

.tooltip-info {
	.tooltip-inner {
		background-color: $primaryColor;
	}

	&.bs-tooltip-top .arrow:before {
		border-top-color: $primaryColor !important;
	}

	&.bs-tooltip-right .arrow:before {
		border-right-color: $primaryColor !important;
	}

	&.bs-tooltip-bottom .arrow:before {
		border-bottom-color: $primaryColor !important;
	}

	&.bs-tooltip-left .arrow:before {
		border-left-color: $primaryColor !important;
	}
}

.auto-complete-container.dropdown-menu {
	margin: 0;
	padding: 0;
	border-radius: 0;
	font-size: $defaultFontSize;
	line-height: $smallLineHeight;
	min-width: auto;

	.auto-complete-results-ds {
		display: table;

		.autocomplete-option-ds {
			display: table-row;

			.autocomplete-option-col-ds {
				display: table-cell;
				border-right: $lightGrey 1px solid;
				padding: 3px;
			}
		}
	}

	.autocomplete-header {
		background-color: $primaryColor;
		color: $white;
		padding: 3px;
		font-weight: $bold;
		border-bottom: $primaryColor;
	}

	.dropdown-item {
		cursor: pointer;
		background-color: $backgroundInput;
		padding: 0;

		.dropdown-item-col {
			border-right: $primaryColor;
			padding: 3px;
			display: inline-flex;

			&:last-child {
				border: none;
			}
		}

		em {
			color: $primaryColorLighter;
			background-color: $yellow;
		}

		&:hover {
			background-color: $complementaryColor;
			color: $white;
		}
	}
}

.dx-datebox {
	border-radius: 0 !important;

	.dx-icon-clear {
		font-size: 12px;
	}

	input,
	.dx-placeholder {
		font-size: 11px;
		padding-right: 0 !important;
	}
}
