@import 'margins';
@import 'variables';

.table_wrapper {
	flex: 1 0 0;
	overflow: hidden;
	@extend .p-5;
	& .dx-widget {
		font-family: $defaultFont;
	}
}

span.flag-icon {
	background-size: contain;
	display: inline-block;
	width: 1.33333333em;
	line-height: 1em;
}

.badge-color-1 {
	background-color: $green;
	color: $white;
}

.badge-error {
	background-color: $red;
	color: $white;
}

.dx-pager {
	padding-top: 5px;
	padding-bottom: 2px;
	font-size: 13px;

	.dx-page-sizes .dx-page-size {
		padding: 4px 6px 5px 6px;
	}
	.dx-pages {
		.dx-navigate-button {
			padding: 5px 6px;
		}
		.dx-page {
			padding: 4px 6px 5px 6px;
		}
	}
}
